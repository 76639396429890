import React from "react"
import { StyledUl, StyledLi, NavLink } from "./styled"

const Menu = props => {
  return (
    <>
      <StyledUl>
        <StyledLi>
          <NavLink {...props} to="/men-s-portraits">
            Men&nbsp;portraits
          </NavLink>
          <StyledUl>
            <StyledLi>
              <NavLink {...props} to="/men-s-portraits/alexander">
                Alexander
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/men-s-portraits/abdel">
                Abdel
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/men-s-portraits/jorge">
                Jorge
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/men-s-portraits/luca">
                Luca
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/men-s-portraits/philipp-sitnikov">
                Philipp&nbsp;Sitnikov
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/men-s-portraits/nikolay">
                Nikolay
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/men-s-portraits/stas-stanis">
                Stas&nbsp;Stanis
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/men-s-portraits/simon">
                Simon
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/men-s-portraits/vasiliy">
                Vasiliy
              </NavLink>
            </StyledLi>
          </StyledUl>
        </StyledLi>
        <StyledLi>
          <NavLink {...props} to="/women-s-portraits">
            Women&nbsp;portraits
          </NavLink>
          <StyledUl>
            <StyledLi>
              <NavLink {...props} to="/women-s-portraits/lady-v">
                Lady V
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/women-s-portraits/daria">
                Daria
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/women-s-portraits/lady-in-red">
                Lady&nbsp;in&nbsp;Red
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/women-s-portraits/questioning-my-faith">
                Questioning&nbsp;My&nbsp;Faith
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/women-s-portraits/laura-sormunen">
                Laura&nbsp;Sormunen
              </NavLink>
            </StyledLi>
            <StyledLi>
              <NavLink {...props} to="/women-s-portraits/ekaterina">
                Ekaterina
              </NavLink>
            </StyledLi>
          </StyledUl>
        </StyledLi>
        {/* <StyledLi>
          <NavLink {...props} to="/nu">
            Nu
          </NavLink>
        </StyledLi> */}
        <StyledLi>
          <NavLink {...props} to="/photo-manipulation">
            Photo&nbsp;manipulation
          </NavLink>
        </StyledLi>
        <StyledLi>
          <NavLink {...props} to="/stas-ans-luisa">
            Stas&nbsp;ans&nbsp;Luisa
          </NavLink>
        </StyledLi>
        <StyledLi>
          <NavLink {...props} to="/analog-photos">
            Analog&nbsp;photos
          </NavLink>
        </StyledLi>
      </StyledUl>
    </>
  )
}
export default Menu
