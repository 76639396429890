import React from "react"
import { StyledUl, StyledLi, NavLink, ALink } from "./styled"

const MainMenu2 = props => {
  return (
    <>
      <StyledUl>
        <StyledLi>
          <NavLink to="/policy" {...props} footermenu="true">
            Policy
          </NavLink>
        </StyledLi>
        <StyledLi>
          <NavLink to="/cookies" {...props} footermenu="true">
            Cookies
          </NavLink>
        </StyledLi>
        <StyledLi>
          <ALink
            href="https://staminaweb.ru"
            {...props}
            footermenu
            target="_blank"
            rel="noopener noreferrer"
          >
            StaminaWeb
          </ALink>
        </StyledLi>
      </StyledUl>
    </>
  )
}
export default MainMenu2
