import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`

const LettersLogo = styled.div`
  font-family: ${props => props.theme.fonts.secondary}, sans-serif;
  display: flex;
  width: 42px;
  height: 42px;
  margin-left: 5px;
  padding-left: 5px;
  border: 2px solid rgb(230, 230, 230);
  font-size: 20px;
  font-weight: bold;
  color: rgb(230, 230, 230);
  align-items: center;
  justify-content: left;
  filter: invert(0);

  ${props => {
    if (props.green) {
      return `
      background-color: hsl(151, 49%, 20%);
      `
    } else if (props.blue) {
      return `
      background-color: hsl(195, 49%, 20%);
      `
    } else if (props.grey) {
      return `
      background-color: grey;
      position: absolute;
      width: 35px;
      height: 35px;
      left: 50%;
      `
    } else if (props.white) {
      return `
      background: none;
      `
    } else {
      return `
      background-color: hsl(315, 49%, 20%);
      `
    }
  }}
`
const LinkNoLine = styled(Link)`
:hover{
  text-decoration: none;
`
const LogoPic = () => {
  return (
    <LinkNoLine to="/">
      <LogoWrapper>
        <LogoWrapper>
          <LettersLogo white>A</LettersLogo>
          <LettersLogo grey>B</LettersLogo>
        </LogoWrapper>
        <LettersLogo green>O</LettersLogo>
        <LettersLogo blue>N</LettersLogo>
        <LettersLogo>D.</LettersLogo>
      </LogoWrapper>
    </LinkNoLine>
  )
}
export default LogoPic
