import React from "react"
import Menu from "../components/Menu"
import { MDXProvider } from "@mdx-js/react"
import Seo from "../components/Seo"
import LineTextLine from "../components/LineTextLine"
import LightBox from "../components/LightBox"
import SimpleReactLightbox from "simple-react-lightbox"
import Message from "../components/Message"
import { ImageBed, Container, Row, Col } from "../components/styled"
import Picture from "../components/Picture"
import AndManyMore from "../components/AndManyMore"
import { Link } from "gatsby"

const shortcodes = {
  Seo,
  Link,
  LineTextLine,
  LightBox,
  SimpleReactLightbox,
  Message,
  ImageBed,
  Container,
  Row,
  Col,
  Picture,
  AndManyMore,
}
const Main = ({ children }) => {
  return (
    <MDXProvider components={shortcodes}>
      <main>
        <Container fluid="md">
          <Row>
            <Col
              xl={3}
              lg={3}
              md={4}
              sm={12}
              className="pt-4 pb-4 d-none d-md-block"
            >
              <Menu />
            </Col>
            <Col className="pt-4 pb-4">{children}</Col>
          </Row>
        </Container>
      </main>
    </MDXProvider>
  )
}
export default Main
