import React from "react"
import styled from "styled-components"

const LineTextLine = ({ title }) => {
  return (
    <>
      <H1Lined>{title}</H1Lined>
    </>
  )
}
export default LineTextLine

const H1Lined = styled.h1`
  display: flex;
  color: ${props => props.theme.colors.secondary};
  flex-direction: row;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding-top: 0.45rem;

  &:after,
  &:before {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid ${props => props.theme.colors.pale};
    margin: auto;
    margin-right: 0.45rem;
    margin-left: 0.45rem;
  }
`
