import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Image = props => (
  <StaticQuery
    query={graphql`
      {
        images: allFile(
          filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }
        ) {
          edges {
            node {
              id
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  quality: 90
                  width: 900
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }
      var altName =
        props.name +
        ". Model: " +
        props.modelName +
        ", " +
        props.place +
        ". Photographer: Andrey Bond."

      const picture = getImage(image.node.childImageSharp.gatsbyImageData)
      return <ImgStyled image={picture} alt={altName} />
    }}
  />
)

export default Image

const ImgStyled = styled(GatsbyImage)`
  text-indent: -9999px;
  color: transparent;
`
