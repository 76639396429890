import PropTypes from "prop-types"
import React from "react"
import { Container, Navbar, Nav, Line } from "../components/styled"
import styled from "styled-components"
import SocialButtons from "../components/SocialButtons"
import Logo from "../components/Logo"
import MainMenu from "../components/MenuMain"
import Menu from "../components/Menu"
import NavDropdown from "react-bootstrap/NavDropdown"

const Header = ({ siteTitle }) => (
  <>
    <Line />
    <HeaderBlock>
      <Container fluid="lg">
        <Navbar sticky="top" variant="dark" expand="md">
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <NavDropdown.Divider />
              <MainMenu muted={true} />
            </Nav>
            <NavDropdown.Divider />
            <SocialButtons />
            <NavDropdown.Divider />
            <div className="d-md-none">
              <Menu muted={true} />
            </div>
          </Navbar.Collapse>
        </Navbar>
      </Container>
      <MessageBlock fluid>
        <Container fluid="lg" className="p-3 text-center">
          <Message>andrey bond. a portrait and event photographer</Message>
        </Container>
      </MessageBlock>
    </HeaderBlock>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const HeaderBlock = styled.header`
  background: ${props => props.theme.colors.primary};
  margin: 0;
`
const MessageBlock = styled(Container)`
  background: ${props => props.theme.colors.indigo};
`
const Message = styled.h1`
  font-family: ${props => props.theme.fonts.secondary}, sans-serif;
  text-transform: uppercase;
  font-size: 0.9vw;
  font-weight: 300;
  letter-spacing: 4px;
  margin: 0;
  color: ${props => props.theme.colors.white};
  opacity: 0.7;
  @media only screen and (max-width: 766px) {
    font-size: 2vw;
  }
`
