import React from "react"
import { StyledUl, StyledLi, NavLink } from "../components/styled"

const MainMenu = props => {
  return (
    <>
      <StyledUl>
        <StyledLi>
          <NavLink to="/" {...props} footermenu="true">
            Home
          </NavLink>
        </StyledLi>
        <StyledLi>
          <NavLink to="/portfolio" {...props} footermenu="true">
            Portfolio
          </NavLink>
        </StyledLi>
        <StyledLi>
          <NavLink to="/about-me" {...props} footermenu="true">
            About&nbsp;me
          </NavLink>
        </StyledLi>
        <StyledLi>
          <NavLink to="/generosity" {...props} footermenu="true">
            Generosity
          </NavLink>
        </StyledLi>
      </StyledUl>
    </>
  )
}
export default MainMenu
