import React from "react"
import { Container, Row, Col, Message } from "../components/styled"
import InstaIco from "../images/instagramico.png"
import styled from "styled-components"

const AndManyMore = () => {
  return (
    <>
      <Container className="pt-2">
        <Row className="align-items-center">
          <Col xl={2} lg={2} md={2} sm={2} xs={12}>
            <PulsingImg src={InstaIco} alt="Andrey Bond Instagram page" />
          </Col>
          <Col>
            <Message>
              <b>...and many more.</b> Please check the menu right to your left
              hand or down below in the footer of the website.
              <br />
              Also you can check my{" "}
              <a
                href="https://instagram.com/andreybond"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>{" "}
              page. <b>Enjoy!</b>
            </Message>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default AndManyMore

const PulsingImg = styled.img`
  background: black;
  border-radius: 50%;
  margin: 10px;
  height: 50px;
  width: 50px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
  @keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`
