import React from "react"
import styled from "styled-components"
import { Container, Line, Row, Col } from "../components/styled"
import MainMenu from "../components/MenuMain"
import MainMenu2 from "./MenuMain2"
import MainMenu3 from "./MenuMain3"

const FooterApp = ({ siteTitle }) => {
  return (
    <>
      <Line />
      <Footer>
        <Container fluid="lg" className="pt-3">
          <Row>
            <Col>
              <MainMenu muted={true} />
            </Col>
            <Col>
              <MainMenu3 muted={true} />
            </Col>
            <Col>
              <MainMenu2 muted={true} />
            </Col>
            <Col p5="true">
              <a
                href="https://beget.com/?id=1127859"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Beget Sponsor Andrey Bond"
                  src="https://cp.beget.com/promo_data/static/static100x100_6.png"
                  border="0"
                ></img>
              </a>{" "}
            </Col>
            <Col lg={12} className="p-3">
              <FooterText>
                <small>
                  {siteTitle} © {new Date().getFullYear()}. a portrait and event
                  photographer based in barcelona, spain
                </small>
              </FooterText>
              <FooterText>
                <small className="pl-1">
                  Site developed by{" "}
                  <a href="https://staminaweb.ru/">
                    <span>StaminaWeb</span>
                  </a>
                  . Affordable web design. Andrey Bond's project.Website
                  development, photography, videography, support. Please feel to
                  visit my website.
                </small>
              </FooterText>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  )
}
export default FooterApp

const Footer = styled.footer`
  background: ${props => props.theme.colors.info};
`
const FooterText = styled.section`
  color: ${props => props.theme.colors.pale};
  padding: 5px;
  text-transform: capitalize;
`
