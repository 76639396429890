import React from "react"
import { StyledUl, StyledLi, NavLink } from "./styled"

const MainMenu3 = props => {
  return (
    <>
      <StyledUl>
        <StyledLi>
          <NavLink to="/men-s-portraits" {...props} footermenu="true">
            Men&nbsp;portraits
          </NavLink>
        </StyledLi>
        <StyledLi>
          <NavLink to="/women-s-portraits" {...props} footermenu="true">
            Women&nbsp;portraits
          </NavLink>
        </StyledLi>
        <StyledLi>
          <NavLink to="/photo-manipulation" {...props} footermenu="true">
            Photo&nbsp;manipulation
          </NavLink>
        </StyledLi>
        <StyledLi>
          <NavLink to="/analog-photos" {...props} footermenu="true">
            Analog&nbsp;photos
          </NavLink>
        </StyledLi>
      </StyledUl>
    </>
  )
}
export default MainMenu3
